module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3_deq3uwq4hqo4wdjjssz6e6yp74/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tap Ants - The Next Big Tapping Game","short_name":"Tap Ants","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f5b8d57f8d3b0496fe4f70d078564a61"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-canonical-urls@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-do_gwm7jv5haus5uwro5jinvottxi/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.tapants.com"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.13.3_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3._wtvzkfew7fj65a6k577h7h5m5i/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-nprogress@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18._wanzlche3gihrnl23tcc7wqrce/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-i18next@3.0.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@_miupnk7fbuxrujmdjknmfu4a7a/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en"],"defaultLanguage":"en","siteUrl":"https://www.tapants.com","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-posthog@1.0.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_gma74wla3qpoqvbgi54d5rqzvm/node_modules/gatsby-plugin-posthog/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"phc_s5D9h91M05mpE2q1vky1VDaZEFmtkwQv7Ocm7HOU4qO","apiHost":"https://us.i.posthog.com","head":true,"isEnabledDevMode":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__j3nwtvwgkwanxqfskc63nnmlhm/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
